<template>
  <div id="materialReportCarton">
    <el-dialog
      :title="materialReportCartonFormTitle"
      width="680px"
      :visible.sync="materialReportCartonDialogVisible"
      :close-on-click-modal="false"
      @close="materialReportCartonDialogClose"
    >
      <el-form
        ref="materialReportCartonFormRef"
        :model="materialReportCartonForm"
        :rules="materialReportCartonFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品(物料)名称" prop="materialName">
              <el-input v-model="materialReportCartonForm.materialName" placeholder="请输入产品(物料)名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产批号" prop="batchNum">
              <el-input v-model="materialReportCartonForm.batchNum" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批量" prop="batchSize">
              <el-input v-model="materialReportCartonForm.batchSize" placeholder="请输入批量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格" prop="spec">
              <el-input v-model="materialReportCartonForm.spec" placeholder="请输入规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请验日期" prop="checkDate">
              <el-date-picker v-model="materialReportCartonForm.checkDate" placeholder="请选择请验日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="materialReportCartonForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验依据" prop="basis">
              <el-input v-model="materialReportCartonForm.basis" placeholder="请输入检验依据" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="外观：外观整洁，平整；无破损或污迹，整批色泽均一，无明显色差，印刷清晰，位置准确，粘合接缝处无多余粘合剂溢出" prop="appearance" label-width="460px">
              <el-radio-group v-model="materialReportCartonForm.appearance">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="材质：材质为双瓦楞（五层）纸板" prop="materialQuality" label-width="460px">
              <el-radio-group v-model="materialReportCartonForm.materialQuality">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="印刷：内容、排版及颜色与样板一致" prop="printing" label-width="460px">
              <el-radio-group v-model="materialReportCartonForm.printing">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="尺寸：尺寸大小符合要求，误差±5mm" prop="size" label-width="460px">
              <el-radio-group v-model="materialReportCartonForm.size">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="装配质量：装配好的纸箱方正，粘接牢固，无错位、无脱开；多次开合面层和里层不得出现裂缝" prop="assemblyQuality" label-width="460px">
              <el-radio-group v-model="materialReportCartonForm.assemblyQuality">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验结论" prop="conclusion">
              <el-radio-group v-model="materialReportCartonForm.conclusion">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="materialReportCartonForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="materialReportCartonForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reporterDate">
              <el-date-picker v-model="materialReportCartonForm.reporterDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="materialReportCartonForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="reviewerDate">
              <el-date-picker v-model="materialReportCartonForm.reviewerDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="materialReportCartonForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="approverDate">
              <el-date-picker v-model="materialReportCartonForm.approverDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="materialReportCartonDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialReportCartonFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品(物料)名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入产品(物料)名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialReportCartonPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="产品(物料)名称" />
      <el-table-column prop="batchNum" label="生产批号" />
      <el-table-column prop="batchSize" label="批量" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column label="请验日期">
        <template slot-scope="scope">
          <span v-if="scope.row.checkDate">{{ scope.row.checkDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="basis" label="检验依据" />
      <el-table-column label="外观">
        <template slot-scope="scope">
          <span v-if="scope.row.appearance === 0">不合格</span>
          <span v-if="scope.row.appearance === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="材质">
        <template slot-scope="scope">
          <span v-if="scope.row.materialQuality === 0">不合格</span>
          <span v-if="scope.row.materialQuality === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="印刷">
        <template slot-scope="scope">
          <span v-if="scope.row.printing === 0">不合格</span>
          <span v-if="scope.row.printing === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="尺寸">
        <template slot-scope="scope">
          <span v-if="scope.row.size === 0">不合格</span>
          <span v-if="scope.row.size === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="装配质量">
        <template slot-scope="scope">
          <span v-if="scope.row.assemblyQuality === 0">不合格</span>
          <span v-if="scope.row.assemblyQuality === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column label="检验结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="reporter" label="报告人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reporterDate">{{ scope.row.reporterDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approverDate">{{ scope.row.approverDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialReportCartonPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addMaterialReport, deleteMaterialReport, updateMaterialReport, selectMaterialReportInfo, selectMaterialReportList } from '@/api/quality/materialReport'

export default {
  data () {
    return {
      materialReportCartonDialogVisible: false,
      materialReportCartonFormTitle: '',
      materialReportCartonForm: {
        id: '',
        materialName: '',
        batchNum: '',
        batchSize: '',
        spec: '',
        checkDate: '',
        reportDate: '',
        basis: '',
        appearance: '',
        materialQuality: '',
        printing: '',
        size: '',
        assemblyQuality: '',
        conclusion: '',
        remarks: '',
        reporter: '',
        reporterDate: '',
        reviewer: '',
        reviewerDate: '',
        approver: '',
        approverDate: '',
        type: 7
      },
      materialReportCartonFormRules: {
        materialName: [{ required: true, message: '产品(物料)名称不能为空', trigger: ['blur', 'change']}]
      },
      materialReportCartonPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: '',
        type: 7
      }
    }
  },
  created () {
    selectMaterialReportList(this.searchForm).then(res => {
      this.materialReportCartonPage = res
    })
  },
  methods: {
    materialReportCartonDialogClose () {
      this.$refs.materialReportCartonFormRef.resetFields()
      // document.getElementById('materialReportCarton')
      //   .querySelector('.el-dialog__wrapper')
      //   .scrollTop = 0
    },
    materialReportCartonFormSubmit () {
      if (this.materialReportCartonFormTitle === '纸箱原料检验报告书详情') {
        this.materialReportCartonDialogVisible = false
        return
      }
      this.$refs.materialReportCartonFormRef.validate(async valid => {
        if (valid) {
          if (this.materialReportCartonFormTitle === '新增纸箱原料检验报告书') {
            await addMaterialReport(this.materialReportCartonForm)
          } else if (this.materialReportCartonFormTitle === '修改纸箱原料检验报告书') {
            await updateMaterialReport(this.materialReportCartonForm)
          }
          this.materialReportCartonPage = await selectMaterialReportList(this.searchForm)
          this.materialReportCartonDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialReportCartonFormTitle = '新增纸箱原料检验报告书'
      this.materialReportCartonDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialReport(row.id)
        if (this.materialReportCartonPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialReportCartonPage = await selectMaterialReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialReportCartonFormTitle = '修改纸箱原料检验报告书'
      this.materialReportCartonDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialReportCartonFormTitle = '纸箱原料检验报告书详情'
      this.materialReportCartonDialogVisible = true
      this.selectMaterialReportInfoById(row.id)
    },
    selectMaterialReportInfoById (id) {
      selectMaterialReportInfo(id).then(res => {
        this.materialReportCartonForm.id = res.id
        this.materialReportCartonForm.materialName = res.materialName
        this.materialReportCartonForm.batchNum = res.batchNum
        this.materialReportCartonForm.batchSize = res.batchSize
        this.materialReportCartonForm.spec = res.spec
        this.materialReportCartonForm.checkDate = res.checkDate
        this.materialReportCartonForm.reportDate = res.reportDate
        this.materialReportCartonForm.basis = res.basis
        this.materialReportCartonForm.appearance = res.appearance
        this.materialReportCartonForm.materialQuality = res.materialQuality
        this.materialReportCartonForm.printing = res.printing
        this.materialReportCartonForm.size = res.size
        this.materialReportCartonForm.assemblyQuality = res.assemblyQuality
        this.materialReportCartonForm.conclusion = res.conclusion
        this.materialReportCartonForm.remarks = res.remarks
        this.materialReportCartonForm.reporter = res.reporter
        this.materialReportCartonForm.reporterDate = res.reporterDate
        this.materialReportCartonForm.reviewer = res.reviewer
        this.materialReportCartonForm.reviewerDate = res.reviewerDate
        this.materialReportCartonForm.approver = res.approver
        this.materialReportCartonForm.approverDate = res.approverDate
        this.materialReportCartonForm.type = res.type
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportCartonPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportCartonPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialReportList(this.searchForm).then(res => {
        this.materialReportCartonPage = res
      })
    }
  }
}
</script>

<style>
</style>
